@import '../shared/colors';

.color {
	&--main {
		color: $brand-color;
	}

	&--main-light {
		color: #FF33B5;
	}
}
