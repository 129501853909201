@import 'shared/layout-constants';

.page-main {
	&.movie {

		.footer .footer__section--menu,
		.footer .footer__section--phone,
		.upper-header {
			position: relative;

			&::after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: black;
				z-index: 1;
				content: '';
				display: block;
			}
		}

		.header header,
		.footer {
			box-shadow: none;
		}

		.footer__copy,
		.cookie-consent,
		.header .stock,
		.header .right-side-header,
		.header .menu{
			opacity: 0;
		}
	}

	.fancybox__nav,
	.fancybox__footer {
		display: none !important;
	}

	.mat-drawer-container {
		background: black;
	}
}
