@import '../shared/colors';
@import '../shared/mixins';

/**
@deprecated
Стили старой кнопки. Используем вместо нее ewa-button директиву
Стили сделаны глобальными, по причине необходимости
их использования вне компонентов - в редакторе текста
в админке, чтобы вставлять в описания товаров
 */


$dark-filled: (
	text: #fff,
	bg: #272727,
	bg-hover: #525252,
	bg-disabled: #b3b3b3,
);

$white-filled: (
	text: #000,
	bg: #fff,
	bg-hover: #b3b3b3,
);

$dark-pill: (
	text: #fff,
	bg: #272727,
	bg-hover: #525252,
);

$white-pill: (
	text: black,
	bg: #fff,
	bg-hover: #525252,
);

$outlined: (
	text: black,
	text-hover: #7a7a7a,
	bg: #fff,
);

.app-ewa-button {
	@include font-raleway;
	@include animate-bg;
	box-sizing: border-box;
	border: 1px solid transparent;
	width: max-content;
	position: relative;
	overflow: hidden;
	outline: none;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;

	&.pink {
		font-weight: 500;
		font-family: 'TT Firs Neue', sans-serif !important;

		&:hover, &.active, &:focus {
			background-color: #fff !important;
			border: 1px solid #E0008D !important;
			color: #E0008D !important;
		}
	}

	border-radius: 0.5rem;

	font-weight: bold;
	text-transform: uppercase;

	overflow: hidden;

	.button-content.loading {
		pointer-events: none;
		visibility: hidden;
	}

	.ripple {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	// Size styles
	&.large {
		font-size: 1.5rem;
		padding: 0.75rem 1rem;
		border-radius: 0.75rem;
		@media (max-width: $sm) {
			font-size: 1.2rem; // потому что в магазине текст на кнопке не помещался
			padding: 0.7rem 0.8rem;
		}
	}

	&.medium {
		font-size: 1.125rem;
		padding: 0.5rem 0.75rem;
	}

	&.small {
		font-size: 1rem;
		padding: 0.5rem 0.75rem;
	}

	// Shape styles
	//почему в стилях формы мы дополнительно стилизуем кнопку?
	&.pill {
		border-radius: 100rem;
		font-weight: 400;
		text-transform: none;
	}

	// Outline styles
	&.outlined {
		color: map-get($map: $outlined, $key: text);
		background-color: map-get($map: $outlined, $key: bg);
		border: 1px solid map-get($map: $outlined, $key: text);

		&:hover {
			border: 1px solid map-get($map: $outlined, $key: text-hover);
			color: map-get($map: $outlined, $key: text-hover);
		}

		&:disabled {
			border: 1px solid map-get($map: $outlined, $key: text-hover);
			color: map-get($map: $outlined, $key: text-hover);
		}
	}

	&.black {
		color: #fff;
		background-color: #000;

		&:hover {
			background-color: #272727;
		}
	}

	&.white-filled {
		color: map-get($map: $white-filled, $key: text);
		background-color: map-get($map: $white-filled, $key: bg);

		&:hover {
			color: map-get($map: $white-filled, $key: hover);
		}
	}

	&.dark-filled {
		color: map-get($map: $dark-filled, $key: text);
		background-color: map-get($map: $dark-filled, $key: bg);

		&:hover {
			background-color: map-get($map: $dark-filled, $key: bg-hover);
		}

		&:disabled {
			background-color: map-get($map: $dark-filled, $key: bg-disabled);
		}
	}

	&.no-border {
		border-width: 0;

		&:hover {
			border-width: 0;
			color: map-get($map: $outlined, $key: text-hover);
		}
	}

	&:disabled {
		pointer-events: none;
	}

	mat-spinner {
		position: absolute;
		width: auto;
		height: 75% !important;
	}
}
