@import 'shared/vw';

.round-select {
    .mat-mdc-text-field-wrapper {
        background: #272727 !important;
        border-radius: getVwDesktop(100px) !important;
    }

    .mat-mdc-select-value-text,
    .mat-mdc-select-arrow {
        color: white !important;
    }

    .mat-mdc-select-value-text {
        font-size: getVwDesktop(16px) !important;
    }

    .mat-mdc-form-field-infix {
        padding-top: getVwDesktop(12px) !important;
        padding-bottom: getVwDesktop(12px) !important;
        min-height: unset !important;
        width: getVwDesktop(140px) !important;
    }

    &.mat-mdc-select-panel {
        margin-top: getVwDesktop(12px) !important;
        background-color: #272727 !important;
        border-radius: getVwDesktop(24px) !important;
        padding: getVwDesktop(22px) getVwDesktop(20px) !important;

        .mat-mdc-option {
            //min-height: getVwDesktop(36px) !important;
            padding: 0 !important;

            &:not(:last-child) {
                border-bottom: getVwDesktop(1px) solid #3F3F3F !important;
            }
        }

        .mdc-list-item__primary-text {
            color: white !important;
        }
    }

    &--dark {
        .mat-mdc-text-field-wrapper {
            background: #161616 !important;
        }
    }

    @media screen and (max-width: 1024px) {
        .mat-mdc-select-value-text {
            font-size: getVwMobile(12px) !important;
        }
    }
}
