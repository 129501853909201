@import 'shared/breakpoints';
@import 'shared/colors';
@import 'shared/vw';

@mixin hide-xl {
  @media (max-width: $xl) {
    display: none;
  }
}

@mixin hide-lg {
  @media (max-width: $lg) {
    display: none;
  }
}

@mixin hide-md {
  @media (max-width: $md) {
    display: none;
  }
}

@mixin hide-sm {
  @media (max-width: $sm) {
    display: none;
  }
}

@mixin hide-xs {
  @media (max-width: $xs) {
    display: none;
  }
}

@mixin appear-xl {
  @media (min-width: calc($xl + 1px)) {
    display: none;
  }
}

@mixin appear-lg {
  @media (min-width: calc($lg + 1px)) {
    display: none;
  }
}

@mixin appear-md {
  @media (min-width: calc($md + 1px)) {
    display: none;
  }
}

@mixin appear-sm {
  @media (min-width: calc($sm + 1px)) {
    display: none;
  }
}

@mixin appear-xs {
  @media (min-width: calc($xs + 1px)) {
    display: none;
  }
}

@mixin animate-hover-text {
  transition: all 0.07s linear;
}

@mixin animate-bg {
  transition: all 0.1s linear;
}

@mixin animate-border {
  transition: border-color 0.1s ease-in-out;
}

@mixin text-hover-brand {
  &:hover {
    color: $brand-color;
  }
  @include animate-hover-text;
}

@mixin font-din-condensed {
  font-family: 'DIN Condensed', sans-serif !important;
}

@mixin font-raleway {
  font-family: 'Raleway', sans-serif !important;
}

@mixin font-bebas-neue {
  font-family: 'BebasNeue', sans-serif !important;
}

@mixin font-league-gothic {
  font-family: 'LeagueGothic', sans-serif !important;
}

@mixin font-montserrat {
  font-family: 'Montserrat', sans-serif !important;
}

@mixin font-geometria {
  font-family: 'Geometria', sans-serif !important;
}

@mixin font-ttfirsneue {
  font-family: 'TT Firs Neue', sans-serif !important;
}

@mixin flex($gap: 0, $jc: flex-start, $ai: center) {
  display: flex;
  flex-direction: row;
  gap: $gap;
  justify-content: $jc;
  align-items: $ai;
}

@mixin flex-column($gap: 0, $jc: flex-start, $ai: flex-start) {
  display: flex;
  flex-direction: column;
  gap: $gap;
  justify-content: $jc;
  align-items: $ai;
}

@mixin flex-center($gap: 0) {
  @include flex($gap, center, center);
}

@mixin pseudo-default($width: 100%, $height: 100%, $left: 0, $top: 0) {
  content: '';
  position: absolute;
  width: $width;
  height: $height;
  left: $left;
  top: $top;
}

@mixin position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*
Use it like this:
span {
  & {
    position: relative;
  }
  &:after {
    content: '';
    @include hover-underline($underline-height, $underline-offset, $color)
  }
  &:hover:after {
    width: 100%;
  }
}
 */
@mixin hover-underline($underline-height: 1px, $underline-offset: -2px, $color: $black) {
  display: block;
  background-color: $color;
  height: $underline-height;
  position: absolute;
  bottom: $underline-offset;
  transition: width 0.25s ease 0s;
  width: 0; // make this 100% on hover
}

@mixin size($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
}

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@mixin align-numbers {
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}

@mixin text-gradient($color1, $color2) {
  background: -webkit-linear-gradient(to right, $color1, $color2);
  background: -moz-linear-gradient(to right, $color1, $color2);
  background: -o-linear-gradient(to right, $color1, $color2);
  background: linear-gradient(to right, $color1, $color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  width: fit-content;
}

@mixin min-width-lg() {
  @media screen and (min-width: $lg) {
    @content;
  }
}

@mixin min-width-desktop() {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin adaptive-prop-size($property, $mobilePx, $desktopPx) {
  @if($mobilePx == auto) {
    #{$property}: auto
  } @else {
    #{$property}: getVwMobile($mobilePx);
  }

  @include min-width-lg() {
    @if($desktopPx == auto) {
      #{$property}: auto
    } @else {
      #{$property}: getVwDesktop($desktopPx);
    }
  }

  @include min-width-desktop() {
    #{$property}: $desktopPx;
  }
}
