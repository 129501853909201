@import '../shared/mixins';

.mat-mdc-checkbox {

	.mdc-checkbox__checkmark {
		color: black !important;
	}

	.mdc-checkbox__background {
		border-color: black !important;
		background-color: transparent !important;
	}

	.mdc-form-field {
		display: flex !important;
		flex-direction: row-reverse !important;

		label {
			@include font-montserrat;
			font-weight: 400 !important;
			font-size: 11px !important;
			color: #676767 !important;
		}
	}
}
