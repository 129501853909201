button.mat-mdc-menu-item {
	min-height: auto;
	padding: 8px 12px;
	border-radius: 8px;
	min-width: 115px;

	span {
		@include font-raleway;
		font-size: 14px !important;
	}

	&:hover {
		span {
			color: $brand-color !important;
		}
	}

	&:hover {
		background: transparent !important;
	}
}
