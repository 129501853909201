@import 'reset';
@import 'shared/mixins';
@import 'shared/colors';
@import 'shared/breakpoints';
@import 'fonts';
@import 'typography';
@import '@material-design-icons/font';
@import 'material/all';
@import 'components/all';
@import 'utilities/all';
@import 'swiper/slider';
@import 'pages/all';
@import 'stream/stream';

html,
body {
	margin: 0;
	height: 100%;
	@include font-raleway;
	background: #fafafa;

	@media (max-width: $xs) {
		font-size: 0.9375rem; // 15px
	}
}

a {
	@include text-hover-brand;
	cursor: pointer;
}

input {
	outline: none;
}

html {
	scrollbar-gutter: stable;
}

.full-width {
	width: 100%;
}

.mat-mdc-text-field-wrapper,
.mat-mdc-form-field-focus-overlay {
	background-color: transparent !important;
}

.mat-mdc-form-field-infix {
	width: 140px !important; // чтобы материаловские поля могли быть уже (там по умолчанию 180px)
}

.mat-mdc-form-field {
	@include font-raleway;
}

mat-checkbox {
	::ng-deep label {
		@include font-raleway;
		font-size: 0.75rem;
	}
}

input {
	line-height: 1.5;
}

// Класс скрывает флаг в правом нижнем углу карты
.app-leaflet-map {
	position: relative;

	&:after {
		content: 'leaflet maps';
		display: flex;
		justify-content: center;
		align-items: center;
		@include font-raleway;
		font-size: 0.8rem;
		color: #7a7a7a;
		position: absolute;
		right: 0;
		bottom: 0;
		height: 20px;
		width: 92px;
		background-color: white;
		z-index: 1000;
	}
}

.tshirt-table-size {
	display: flex;
	gap: 1rem;

	@media (max-width: $sm) {
		flex-direction: column;
		font-size: 11px;

		.tshirt-size-image {
			margin: 0 auto;
		}
	}

	.tshirt-size-image {
		max-width: 200px;
	}
}

// фикс цифр, которые заходят то ниже строки, то выше
.mat-expansion-panel-content {
	font: inherit;
}

.img-fill-container {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.bold {
	font-weight: 700;
}

.brand-color {
	color: $brand-color;
}


.dot {
    display: inline-block;
    width: getVwDesktop(12px);
    height: getVwDesktop(12px);
    border-radius: 50%;
    overflow: hidden;
    margin-right: getVwDesktop(8px);

    &--white {
        background: white;
    }

    &--pink {
        background: #E0008D;
    }

    @media screen and (max-width: 1024px) {
        width: getVwMobile(8px);
        height: getVwMobile(8px);
    }
}

.spacer {
	flex-grow: 1;

}
