// 1. DEFINE PALETTE
$ewa-palette: (
	50: #f8e1ef,
	100: #efb5d9,
	200: #e883bf,
	300: #e24ca4,
	400: #e0008e,
	500: #e00076,
	600: #ce0072,
	700: #b8006c,
	800: #a20067,
	900: #7a005d,
	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(black, 0.87),
		400: #fff,
		500: #fff,
		600: #fff,
		700: #fff,
		800: #fff,
		900: #fff
	)
);

// 2. DEFINE THEME
@use '@angular/material' as mat;

@import 'shared/mixins';
@import 'shared/colors';

$ewa-primary: mat.define-palette($ewa-palette);
$ewa-accent: mat.define-palette($ewa-palette, 400);

$ewa-theme: mat.define-light-theme(
	(
		color: (
			primary: $ewa-primary,
			accent: $ewa-accent
		),
		density: 0
	)
);

@include mat.all-component-themes($ewa-theme);

:root {
	--mdc-shape-medium: 8px;
	--mdc-typography-body1-font-family: 'Raleway', 'sans-serif';
	--mdc-typography-font-family: 'Raleway', 'sans-serif';
	--mdc-typography-body2-font-family: 'Raleway', 'sans-serif';
	--mdc-typography-caption-font-family: 'Raleway', 'sans-serif';
	--mdc-dialog-supporting-text-font: 'Raleway', 'sans-serif';
}

.fullscreen-modal {
	background: black;
}

// menu styles
.mat-mdc-menu-panel {
	background: #272727;
}

.mat-mdc-menu-content {
	padding: 8px !important;
	background: white;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.85) !important;
}
