@import 'shared/mixins';
@import 'shared/colors';
@import 'shared/breakpoints';
@import 'shared/vw';

.ewa-page-title {
  @include font-bebas-neue();
  color: $money-black;
  font-size: getVwMobile(24px);
  font-weight: 700;

  @media (min-width: $lg) {
    font-size: getVwDesktop(64px);
  }

  @media (min-width: $desktop) {
    font-size: 64px;
  }
}
