@import 'shared/vw';

.chart-tabs {
    .mdc-tab__text-label {
        font-family: "TT Firs Neue", sans-serif !important;
        font-weight: 400 !important;
        font-size: getVwDesktop(20px) !important;
        letter-spacing: getVwDesktop(-0.50px) !important;
    }

    .mdc-tab-indicator__content {
        border-color: #3F3F3F !important;
        opacity: 1 !important;
        border-top-width: getVwDesktop(2px) !important;
    }

    .mdc-tab__text-label {
        color: #686868 !important;
    }

    .mdc-tab--active {
        .mdc-tab-indicator__content {
            border-color: #E0008D !important;
        }
        .mdc-tab__text-label {
            color: #E0008D !important;
        }
    }

    @media screen and (max-width: 1024px) {
        .mdc-tab__text-label {
            font-size: getVwMobile(12px) !important;
        }
    }
}

.tab-group {
    &--no-header {
        .mat-mdc-tab-header {
            display: none !important;
        }
    }
}
