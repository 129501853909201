@import 'shared/vw';

.analytics-datepicker {
    flex: 1 !important;

    .mat-mdc-form-field-infix {
        width: 100% !important;
    }
    .mat-mdc-form-field {
        width: 100% !important;
        flex: 1 !important;
    }
    .mdc-line-ripple::before {
        border-bottom-width: getVwDesktop(2px) !important;
        border-bottom-color: #E0008D !important;
    }
    .mdc-line-ripple::after {
        border-bottom-width: getVwDesktop(2px) !important;
        border-bottom-color: #E0008D !important;
    }
    .mdc-floating-label {
        color: #E0008D !important;
        font-family: "TT Firs Neue", sans-serif !important;
        font-weight: 400 !important;
        font-size: getVwDesktop(20px) !important;
        letter-spacing: getVwDesktop(-0.50px) !important;
    }
    .mdc-icon-button {
        color: #E0008D !important;
    }
    .mat-mdc-form-field-hint {
        color: #E0008D !important;
    }
    .mat-date-range-input-inner {
        color: white !important;


        &::placeholder {
            font-family: "TT Firs Neue", sans-serif !important;
            font-weight: 400 !important;
            font-size: getVwDesktop(20px) !important;
            letter-spacing: getVwDesktop(-0.50px) !important;
        }
    }
    .mat-date-range-input-separator {
        margin: 0 getVwDesktop(6px) !important;
        color: white !important;
    }
}
