$main-pulse-timing: 0.6s;

@keyframes shine {
	0% {
		fill: rgba(white, 0.65);
	}
	20% {
		fill: rgba(white, 0.75);
	}
	40% {
		fill: rgba(white, 0.95);
	}
	80% {
		fill: rgba(white, 0.95);
	}
	100% {
		fill: rgba(white, 0.65);
	}
}

@keyframes main-pulsate {
	from {
		box-shadow:
			0 0 0 8px rgba(white, 0.2),
			8px 8px 10px rgba(white, 0.2);
	}
	to {
		box-shadow:
			0 0 0 12px rgba(white, 0),
			12px 12px 10px rgba(white, 0);
	}
}

@keyframes main-contrast-element-pulse {
	from {
		border-color: rgba(black, 0.75);
	}
	to {
		border-color: rgba(black, 0.65);
	}
}
