* {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 100%;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // Numbers align
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  -o-font-feature-settings: 'lnum';
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  font: inherit;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}
